<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4 pv3">
        <div class="tc">
          <div class="ba b--light-gray bw2">
            <h1 class="f3 f2-ns ttu vidaloka">
              {{ $t("order.success_title") }}
            </h1>
          </div>
        </div>
        <article class="karla tracked-light lh-copy">
          <p
            v-html="
              $t('order.success_article', {
                order_hash: this.$route.params.order_hash
              })
            "
          ></p>

          <button
            @click="onClick"
            class="w-100 f6 fw6 dim dib ttu tracked b white bg-black link tc ph3 pv2 mb2 mt1 lh-copy roboto sans-serif"
          >
            {{ $t("order.success_continue") }}
          </button>
        </article>
      </div>
    </article>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "order-success",
  components: {},
  methods: {
    onClick() {
      this.$router.push({ name: "home" });
    }
  },
  computed: {},
  mounted() {},
  created() {}
};
</script>

<style lang="stylus"></style>
